
            import {main} from "./main";
            import {Platform} from "./Platform";
            import configJSON from "./assets/config.json?raw";
            import assetPaths from "./sdk/paths/vite";
            if (true) {
                assetPaths.serviceWorker = "sw.js";
            }
            const platform = new Platform(
                document.body,
                assetPaths,
                JSON.parse(configJSON),
                {development: false}
            );
            main(platform);
        